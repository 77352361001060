$(document).ready(function () {
  $('#forgot-password').on('click', function (e) {
    if (!$('#username').val()) {
      $('#more-info').html('Please fill in username and try again');
      $('#more-info').show(500);
      return;
    }
    $.post('database/sendPasswordResetEmail.php', { email: $('#username').val() }, null, 'json')
      .done(function (response) {
        console.log(response);
        if (response.success === 1) {
          // password reset email sent
          $('#more-info').html('Password reset email sent');
          $('#more-info').show(500);

          setTimeout(function () {
            $('#more-info').hide(500);
          }, 3000);
        } else {
          // password reset email sending failed
          $('#more-info').html('Error: ' + response.error);
          $('#more-info').show(500);
        }
      })
      .fail(function (e) {
        $('#more-info').html('Error ' + e.status + ' - ' + e.responseText);
        $('#more-info').show(500);
      });
  });
})
